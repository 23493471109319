import React, { useEffect, useState } from 'react';
import { getMapMarkers } from '../services/MapsService'
import NavbarComponent from "../components/NavbarComponent";

function MapMarkersPage() {
  const [locations, setLocations] = useState([]);
  const [privateLandsPage, setPrivateLandsPage] = useState(false);

  const getMarkersTitle = (price_per_acre_crore, total_land_size_in_acres, landId) => {
    let markerString = ''
    if (total_land_size_in_acres.acres) {
        markerString+= total_land_size_in_acres.acres + ' acres'
    }

    if (total_land_size_in_acres.guntas) {
        if(markerString) {
            markerString += ' '
        }
        markerString+= total_land_size_in_acres.guntas + ' guntas'
    }

    let priceString = '';
    if(price_per_acre_crore.crore) {
        priceString+= price_per_acre_crore.crore + ' crores';
    }

    if(price_per_acre_crore.lakh) {
        if(priceString) {
            priceString += ' ';
        }
        priceString+= price_per_acre_crore.crore + ' lakhs'
    }
    priceString = ` -   ${priceString}   per acre   <a target='_blank' href='https://1acre.in/verified-lands/land/view/${landId}'>(Click to View Land)</a>`;
    return markerString + priceString;

  }
  useEffect(() => {
    async function fetchData() {
      try {
        // Replace this with your actual API endpoint URL

        // Fetch map markers from your API
        const fullUrl = window.location.pathname;
        let privateLandsPageBool = false;
        if(fullUrl.includes('private-map')) {
          privateLandsPageBool = true;
          setPrivateLandsPage(true)
        }

        const response = await getMapMarkers(privateLandsPageBool);
        // debugger;

        // const data = await response.json();
        setLocations(response);

        // Check if the Google Maps API is available
        if (window.google && window.google.maps) {
          // Initialize the map
          initMap(response);
        } else {
          console.error('Google Maps API is not available.');
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    }

    fetchData();
  }, []);

  function initMap(locations) {
    // Check if the map element with ID "map" exists
    const mapElement = document.getElementById('map');
    if (!mapElement) {
      console.error('Map element with id "map" not found.');
      return;
    }

    // Initialize the Google Map
    const map = new window.google.maps.Map(mapElement, {
      zoom: 10, // Adjust the initial zoom level
      center: { lat: 17.3365, lng: 77.9048 }, // Default center (San Francisco)
    });

    // Create markers and info windows
    locations.forEach((location) => {
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(location.lat), lng: parseFloat(location.long) },
        map,
        title: getMarkersTitle(location.price_per_acre_crore, location.total_land_size_in_acres, location.id),
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: `<div><h2><b>${getMarkersTitle(location.price_per_acre_crore, location.total_land_size_in_acres, location.id)}</b></h2></div>`,
      });

      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
    });
  }

  return (
    <div>
        <NavbarComponent />
        <div className='lands-heading'>
          {privateLandsPage ? 'Lands(Private)': 'Lands(All)'}
        </div>
      <div id="map" style={{ height: 'calc(100vh - 200px)', width: 'calc(100vw - 200px)', margin: '0 auto' }}></div>
    </div>
  );
}

export default MapMarkersPage;
