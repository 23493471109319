import { useState } from "react";
import SingleInputFieldModalComponent from "./SingleInputFieldModalComponent";
import { generateOtp, authenticateOtp } from "../services/UserService";
import { isPhoneNumber, isOtp } from "../utils/validators";

export default function LoginComponent({ onSuccessHandler, onCloseHandler }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [isValidOtp, setIsValidOtp] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);

    const phoneNumberChangeHandler = (event) => {
        setIsValidPhoneNumber(() => isPhoneNumber(event.target.value));
        setPhoneNumber(() => event.target.value);
    };

    const otpChangeHandler = (event) => {
        setIsValidOtp(() => isOtp(event.target.value));
        setOtp(() => event.target.value);
    };

    const otpGenerationHandler = async (event) => {
        event.preventDefault();
        try {
            const payload = { phone_number: '91' + phoneNumber }
            const data = await generateOtp(payload);

            // Handle the response from the API
            console.log(data);

            setIsOtpSent(() => true);
        } catch (error) {
            console.log(error);
        }
    };

    const otpAuthenticationHandler = async (event) => {
        event.preventDefault();

        try {
            const payload = { phone_number: '91' + phoneNumber, otp: otp }
            const data = await authenticateOtp(payload);

            localStorage.setItem('authenticationToken', data.token);
            delete data['token'];

            // Handle the response from the API
            onSuccessHandler(data);

            setIsOtpSent(() => false);
            setOtp(() => '');
            setPhoneNumber(() => '');
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <div>
            {!isOtpSent ? (
                <SingleInputFieldModalComponent
                    modalTitle="Login to your safe and trusted space"
                    fieldLabel="Phone Number"
                    fieldValue={phoneNumber}
                    isValidField={isValidPhoneNumber}
                    submitLabel="Generate OTP"
                    fieldValueChangeHandler={phoneNumberChangeHandler}
                    formSubmitHandler={otpGenerationHandler}
                    onCloseHandler={onCloseHandler}
                />
            ) : (
                <SingleInputFieldModalComponent
                    modalTitle="Please enter OTP"
                    fieldLabel="OTP"
                    fieldValue={otp}
                    isValidField={isValidOtp}
                    submitLabel="Confirm OTP"
                    fieldValueChangeHandler={otpChangeHandler}
                    formSubmitHandler={otpAuthenticationHandler}
                    onCloseHandler={onCloseHandler}
                />
            )}
        </div>
    );
}