import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutPage() {
    const navigate = useNavigate();

    localStorage.removeItem('authenticationToken')
    useEffect(() => {
        navigate("/")
    })
    return (
        <div>
            Thank You
        </div>
    );
}
