import { Button, Modal, Input } from 'react-daisyui';

export default function SingleInputFieldModalComponent({ modalTitle, fieldLabel, fieldValue, isValidField, submitLabel, fieldValueChangeHandler, formSubmitHandler, onCloseHandler }) {
    return (
        <div className="font-sans">
            <Modal open>
                <Button
                    size="sm"
                    shape="circle"
                    onClick={onCloseHandler}
                    className="absolute right-2 top-2"
                >
                    ✕
                </Button>
                <Modal.Header className="font-bold pr-5">
                    {modalTitle}
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={formSubmitHandler}>
                        <div className="flex w-full component-preview p-4 items-center justify-center gap-2 font-sans">
                            <div className="form-control w-full max-w-xs min-h-[80px]">
                                <Input
                                    type="text"
                                    placeholder={fieldLabel}
                                    value={fieldValue}
                                    onChange={fieldValueChangeHandler}
                                    required

                                />
                                {!isValidField &&
                                    <label className="label">
                                        <span className="label-text-alt">Enter a valid {fieldLabel}.</span>
                                    </label>
                                }
                            </div>
                        </div>
                        <div className="flex justify-center w-full">
                            <Button  type="submit" color="primary" disabled={!isValidField}>{submitLabel}</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

