import NavbarComponent from "../components/NavbarComponent";
import { fetchQueries } from "../services/QueriesService";
import { useState, useEffect } from "react";
import { Button, Checkbox, Input, InputGroup, Textarea, FileInput, Badge, Table } from "react-daisyui";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function LandPage() {
    // const navigate = useNavigate();
    const params = useParams();
    const [queries, setQueries] = useState([]);
    useEffect(() => {
        (async () => {
            let resp = await fetchQueries({});
            let next = resp.next;
            let allRespRes = resp.results;


            let urlObject = new URL(next);
            let searchParams = urlObject.searchParams;
            let pageValue = searchParams.get("page");

            while(pageValue) {
                let nextResp = await fetchQueries({page: pageValue});
                if(nextResp.next) {
                    urlObject = new URL(nextResp.next);
                    searchParams = urlObject.searchParams;
                    pageValue = searchParams.get("page");   
                } else {
                    pageValue = null;
                }
                allRespRes = [...allRespRes, ...nextResp.results]
            }

              // Sort the results by created_at in descending order
              allRespRes.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return dateB - dateA;
            });
            
            // Convert the dates to IST
            allRespRes.forEach((item) => {
                const istDate = convertGMTtoIST(item.created_at);
                item.created_at_ist = formatISTDate(istDate);
            });
            setQueries(allRespRes);
        })();
    }, [params]);

    const getDirectionsToDisplay = (directions) => {
        let directionsArray = [];
        if(directions.east) {
            directionsArray.push('EAST')
        }
        if(directions.west) {
            directionsArray.push('WEST')
        }
        if(directions.north) {
            directionsArray.push('NORTH')
        }
        if(directions.south) {
            directionsArray.push('SOUTH')
        }
        return directionsArray.join(',');
    }

    // Function to convert GMT to IST
  const convertGMTtoIST = (gmtDate) => {
    const gmtDateTime = new Date(gmtDate);
    // const istOffset = 5.5 * 60 * 60 * 1000;  IST offset in milliseconds
    const istDateTime = new Date(gmtDateTime.getTime());
    return istDateTime;
  };

  const formatISTDate = (istDate) => {
    const options = {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
  
    return new Intl.DateTimeFormat('en-US', options).format(istDate);
  };


    return (
        <div>
            {/* <OverlayComponent /> */}
            <NavbarComponent />
            <div className="m-5">
                <div className="w-4/5 m-auto">
                    {/* <div className="flex w-full items-center justify-center relative"> */}
                    <label className="label text-2xl text-bold text-center">
                        Premium Subscription Queries
                    </label>
                    {/* <Button className="w-48 bg-blue-200 absolute right-0" onClick={(e) => navigate(`/map`)}>
                        View Map
                    </Button> */}
                    {/* </div> */}



                    <div className="flex w-full p-4 mt-4 component-preview gap-2 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
                        <Table className="border-2 border-solid border-[#ffde59]">
                    <Table.Head>
                        <span>ID</span>
                        <span>Phone No.</span>
                        <span>Looking for</span>
                        <span>Investment size</span>
                        <span>Investor</span>
                        <span>Developer</span>
                        <span>Preferred Directions</span>
                        <span>Interested in Grouping</span>
                        <span>Created Time</span>
                    </Table.Head>

                    <Table.Body>
                        {queries && queries.map((query) => (
                                <Table.Row key={`query_${query.id}`}  >
                                <span>{query.id}</span>
                                <span>{query.creator.phone_number}</span>
                                <span>
                                        INSIDE ORR: <b> {query.looking_for.inside_ORR ? 'YES': '-'}</b> <br />
                                        CLOSER TO CITY:<b> {query.looking_for.closer_to_the_city ? 'YES': '-'}</b><br />
                                        LESS THAN 10Km from ORR : <b> {query.looking_for.less_than_10_km_ORR ? 'YES': '-'}</b><br />
                                        Anywhere in Telangana: <b> {query.looking_for.anywhere_in_telangana ? 'YES': '-'}</b><br />
                                        Larger Land parcels: <b> {query.looking_for.larger_land_parcels_in_K_M ? 'YES': '-'}</b><br />
                                        Specific location in mind: <b> {query.looking_for.i_have_a_specific_location_in_mind ? query.looking_for.i_have_a_specific_location_in_mind: '-'}</b><br />
                                </span>
                                <span>{query.selected_investment_size}</span>
                                <span>{query.investor ? 'YES': '-'}</span>
                                <span>{query.developer ? 'YES': '-'}</span>
                                <span>{getDirectionsToDisplay(query.preferred_directions)}</span>
                                <span>{query.interested_in_grouping ? 'YES': '-'}</span>
                                    <span style={{width : '120px', 'display': 'inline-block'}}>{query.created_at_ist ? (query.created_at_ist): '-'}</span>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                    </div>
                    {!queries.length && <div className="text-bold text-center mt-4 text-2xl">No Queries</div>}
                </div>
            </div>
        </div>
    )
}