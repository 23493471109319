import axios from 'axios';

// const BASE_URL = 'https://be.staging.1acre.in';
const BASE_URL = 'https://prod-be.1acre.in';

function getHeaders() {
  let headers = {
    'Content-Type': 'application/json'
  }
  if (localStorage.getItem('authenticationToken')) {
    headers['Authorization'] = `Token ${localStorage.getItem('authenticationToken')}`;
  }
  return { 'headers': headers };
}

axios.defaults.headers.common['Content-Type'] = 'application/json'

export const apiGetService = async (uri, params) => {
  try {
    const response = await axios.get(`${BASE_URL}${uri}`, { params: params, ...getHeaders() });
    console.log(response.status_code)
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const apiPostService = async (uri, jsonPayload) => {
  try {
    const response = await axios.post(`${BASE_URL}${uri}`, jsonPayload, getHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const apiPutService = async (uri, jsonPayload) => {
  try {
    const response = await axios.put(`${BASE_URL}${uri}`, jsonPayload, getHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const apiPatchService = async (uri, jsonPayload) => {
  try {
    const response = await axios.patch(`${BASE_URL}${uri}`, jsonPayload, getHeaders());
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const apiDeleteService = async (uri, jsonPayload) => {
  try {
    const response = await axios.delete(`${BASE_URL}${uri}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
