import { apiGetService, apiPostService, apiPutService } from './ApiService';

export const getPlots = async (params) => {
    return await apiGetService('/plotsadmin/', params);
}

export const getPlotData = async (plot_id) => {
    return await apiGetService(`/plotsadmin/${plot_id}/`);
}

export const createPlot = async (payload) => {
    return await apiPostService('/plotsadmin/', payload);
}

export const updatePlot = async (plot_id, payload) => {
    return await apiPutService(`/plotsadmin/${plot_id}/`, payload);
}

export const getLands = async (params) => {
    return await apiGetService('/lands/admin/', params);
}

export const getLandData = async (land_id) => {
    return await apiGetService(`/lands/admin/${land_id}/`);
}

export const createLand = async (payload) => {
    return await apiPostService('/lands/admin/', payload);
}

export const updateLand = async (land_id, payload) => {
    return await apiPutService(`/lands/admin/${land_id}/`, payload);
}