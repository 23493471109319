import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from 'react-daisyui';
import LoginComponent from "../components/LoginComponent";

export default function HomePage() {
  const navigate = useNavigate();

  const [loginVisible, setLoginVisible] = useState(false);

  const tokenExists = localStorage.getItem('authenticationToken');
  const [userLoginStatus, setUserLoginStatus] = useState(tokenExists);
  const [userData, setUserData] = useState({});

  const onSuccessHandler = (data) => {
    setUserData(data); // change based on status received from data
    setUserLoginStatus(true);
  }

  useEffect(() => {
    if (userLoginStatus) {
      navigate("/lands-dashboard", {
        state: {
          "user": userData
        }
      })
    }
  });
  return (
    <div>
      {!userLoginStatus && (
        <div>
          <div className="flex h-screen">
            <div className="m-auto">
              <Button color="accent" onClick={() => { setLoginVisible(true) }}>Login</Button>
              {loginVisible &&
                <LoginComponent onSuccessHandler={onSuccessHandler} onCloseHandler={() => setLoginVisible(false)} />
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
