import { apiPostService, apiGetService } from './ApiService';

export const generateOtp = async (payload) => {
    return await apiPostService('/account/login/generate_otp/', payload);
}

export const authenticateOtp = async (payload) => {
    return await apiPostService('/account/login/verify_otp/', payload);
}

export const getUser = async () => {
    return await apiGetService('/account/users/');
}

export const getSeller = async (params) => {
    return await apiGetService('/account/seller/', params);
}